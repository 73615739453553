import React,{useState, useEffect} from 'react';
import Layout from '../components/layout';
// import '../styles/page.css'; 
import {createContactUs} from '../graphql/mutations';

import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes} from "@aws-amplify/auth";
import { useTheme } from '../context/ThemeContext';
import { Helmet } from 'react-helmet';
import { StairazAuth } from '../components/userAuth.js';
const ContactUs = ({ signOut, user } ) => {
  
    const client = generateClient();
   
    const { isDarkMode } = useTheme();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        category: 'resume-builder',
        subject:'comment',
        message: ''
      });

      async function currentAuthenticatedUser() {
        try {
          const userData = await fetchUserAttributes();
          const firstName = userData.given_name;
          const lastName = userData.family_name;
          const email = userData.email
          setFormData(prevState => ({ ...prevState, name:`${firstName} ${lastName}`,email:`${email}`}));
    
        } catch (err) {
          localStorage.removeItem('user')
          console.error(err)
    
        }
      }

      useEffect(() => {
        currentAuthenticatedUser()
        const hash = window.location.hash.substring(1);
        const [category, subject] = hash.split('&');
        
        if (category) {
          setFormData(prevState => ({ ...prevState, category }));
        }
        if (subject) {
          setFormData(prevState => ({ ...prevState, subject }));
        }
      }, []);

      useEffect(() => {
        const newHash = `${formData.category}&${formData.subject}`;
        window.location.hash = newHash;
      }, [formData.category, formData.subject]);
      
      const [isSubmitted, setIsSubmitted] = useState(false);
      
      const saveFeedback = async () => {
        
        
        const inputData = {
          name: formData.name, 
          email: formData.email,
          category:formData.category,
          subject:formData.subject,
          message: formData.message, 
        };
        
        try {
          await client.graphql({query:createContactUs, variables:{ input: inputData }});
          console.log('Data saved successfully:');
        } catch (error) {
          console.error('Error saving data:', error);
        }
      };

      useEffect(() => {
        const link = document.getElementById('mode-stylesheet');
        if (link) {
          const additionalLink = document.createElement('link');
          additionalLink.rel = 'stylesheet';
          additionalLink.href = isDarkMode ? '/styles/page.css' : '/styles/pageLight.css';
          document.head.appendChild(additionalLink);
    
          return () => {
            document.head.removeChild(additionalLink);
          };
        }
      }, [isDarkMode]);
    

      useEffect(() => {
    
        if (isSubmitted) {
            saveFeedback();
          
        }
      }, [isSubmitted]);

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        // Implement the logic to handle form submission (e.g., sending data to a server)
      };
    

    if (isSubmitted) {
        return (
          <Layout>
            <section className="section thank-you-section" >
              <h1 className="section-heading">Thank You!</h1>
              <p className="section-content">Thank you for your valuable feedback, we will reply to it as soon as possible.</p>
            </section>
          </Layout>
        );
      }
    
      return (
        <Layout>
           <Helmet>
        <link
          rel="stylesheet"
          href={isDarkMode ? '/styles/page.css' : '/styles/pageLight.css'}
        />
      </Helmet>
        <section className="section" style={{padding:0}}>
          <h1 className="section-heading">Contact Us</h1>
          <form onSubmit={handleSubmit} className="contact-form">
                <div className='contact-item'>
                    <label>Name:</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required/>
                </div>
                <div className='contact-item'>
                    <label>Email:</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required/>
                </div>
                <div className='contact-item'>
                    <label>Category:</label>
                    <select name="category" value={formData.category} onChange={handleChange} required>
                    <option value="resume-builder">Resume Builder</option>
                    <option value="application-tracker">Job Application Tracker</option>
                    <option value="resume-analysis">Resume Scan&Review</option>
                    <option value="job-analysis">Job Analysis</option>
                    <option value="other">Other</option>
                    </select>
                </div>
                <div className='contact-item'>
                    <label>Subject:</label>
                    <select name="subject" value={formData.subject} onChange={handleChange} required>
                    <option value="comment">Leave A Comment</option>
                    <option value="report-an-issue">Report An Issue</option>
                    <option value="other">Other</option>
                    </select>
                </div>
                <div className='contact-item'>
                    <label>Message:</label>
                    <textarea name="message" value={formData.message} onChange={handleChange} required/>
                </div>
                <div className='contact-item'>
                    <button type="submit">Send Message</button>
                </div>
                </form>

        </section>
      </Layout>
      );
    
  };
  
  export default StairazAuth(ContactUs);